.container,
.bp3-navbar {
  max-width: 700pt;
  margin: 0 auto;
}

.bp3-navbar-heading {
    font-weight: bold;
}

.container {
  margin-top: 50pt;
}

footer {
    color: grey;
    font-size: 8pt;
    text-align: right;
    border-top: 1pt solid grey;
    margin-top: 20pt;
}
