.gradient-red-green .bp3-slider-track {
  background: linear-gradient(to right, orange, yellow, cornflowerblue);
}

.gradient-green-blue .bp3-slider-track {
  background: linear-gradient(to right, #6f6, blue);
}

.gradient-red-green-blue .bp3-slider-track {
  background: linear-gradient(to right, red, #6f6, blue);
}

.bp3-slider {
  max-width: 400pt;
  color: #eee;
}

.bp3-slider:hover, .no-answer:hover {
  color: black;
}

.bp3-slider-handle .bp3-slider-label {
    visibility: hidden;
}

.bp3-tab {
    font-weight: bold;
}

.question {
    border-radius: 4pt;
    background-color: #eee;
    padding: 6pt;
    padding-left: 18pt;
    page-break-inside: avoid;
}

.no-answer {
  color: white;
}

/* must set height explicitely (https://github.com/palantir/blueprint/issues/2777#issuecomment-412206258) */

.passed {
  color: gray;
}

.answer .pass {
    visibility: hidden;
}

.answer:hover .pass {
    visibility: visible;
}

.answer {
    display: flex;
}

.pass {
    transition: visibility 1s
}

.answer, .pass {
  margin-left: 25pt;
}

.required {
  font-style: bold;
  font-color: red;
  margin-right: 5pt;
}

button {
  margin-right: 4pt;
}

table.compact td {
    box-shadow: none!important;
    padding-top: 0;
    font-size: 120%;
}

tr.report > td {
    border-bottom: 2px solid #ccc;
}
